@font-face {
    font-family: Pokemon;
    src: url('../../style/fonts/Pokemon\ Classic.ttf');
}

* {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: Pokemon;
}